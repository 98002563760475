import { SET_CONFIG } from "../actions/actionType";

const initialState = {
  isProd: null,
  loginEnabled: false,
  streamTimeoutSec: 3600
}

const ConfigReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_CONFIG:
      return {
        ...state,
        isProd: action.config.env === "prod",
        loginEnabled: action.config.loginEnabled,
        streamTimeoutSec: action.config.streamTimeoutSec || state.streamTimeoutSec
      }

    default:
      return state
  }
}

export default ConfigReducer;