import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from 'redux-thunk'
import rootReducer from "./reducers/rootReducer";
import { loadConfig } from "./actions/configsActions";

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));
store.dispatch(loadConfig())

const rootElement = (
  <Provider store={store}>
      <App />
  </Provider>
);

ReactDOM.render(rootElement, document.getElementById('root'));