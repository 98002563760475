import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { FaFastForward } from "react-icons/fa";
import Hls from "hls.js";

import "./HlsPlayer.css";

class HlsPlayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hls: null,
			timer: null,
			playlistId: null
		}

		this.handleHlsErrors = this.handleHlsErrors.bind(this);
		this.startStream = this.startStream.bind(this);
		this.skipStream = this.skipStream.bind(this);
		this.loadPlayer = this.loadPlayer.bind(this);
		this.cutStream = this.cutStream.bind(this);
		this.handleLoadedData = this.handleLoadedData.bind(this);
		this.handlePause = this.handlePause.bind(this);
		this.handlePlay = this.handlePlay.bind(this);
	}

	componentDidMount() {
		this.player.addEventListener('loadeddata', this.handleLoadedData);
		this.player.addEventListener("pause", this.handlePause);
		this.player.addEventListener("play", this.handlePlay);
	}

	componentWillUnmount() {
		this.player.removeEventListener('loadeddata', this.handleLoadedData);
		this.player.removeEventListener('pause', this.handlePause);
		this.player.removeEventListener('play', this.handlePlay);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.channelId !== this.props.channelId) {
			this.startStream();
		}
	}

	render() {
		return (
			<div id="hls-player">
					<video ref={ player => (this.player = player) } autoPlay={ false } controls></video>
					<Button className="skip-stream-button" onClick={ this.skipStream }>
						<FaFastForward/>
					</Button>
				{ this.props.streamTimeoutSec
					? <p>This stream will be cut after { this.props.streamTimeoutSec / 60 } minutes of inactivity.</p>
					: null
				}
			</div>
		);
	}

	handleHlsErrors(event, data) {
		if (data.fatal) {
			let msg = 'Fatal player error: ' + data.type + ' - ' + data.details;
			console.error(msg);
		}
		switch (data.type) {
			case Hls.ErrorTypes.MEDIA_ERROR:
				this.state.hls.recoverMediaError();
				break;
			case Hls.ErrorTypes.NETWORK_ERROR:
				// Retry
				this.state.hls.startLoad();
				break;
			default:
				if (data.fatal) {
					console.error('unrecoverable error');
					this.state.hls.destroy();
				}
				break;
		}
	}

	startStream() {
		if (this.props.channelId) {
			fetch(`/api/createHlsStream?channelId=${ this.props.channelId }`, {
				method: 'POST',
				headers: {
					'session-id': this.props.sessionId
				}
			})
				.then(async response => {
					if (response.ok) {
						let data = await response.json();
						this.setState({playlistId: data.playlistId},
							() => {
								this.loadPlayer(data.streamUrl);
								this.props.onStartCallback(data.playlistId)
							});
					}
				})
				.catch(error => console.log('An error occured while creating stream: ' + error));
		}
	}

	skipStream() {
		if (this.state.playlistId) {
			fetch(`/api/skipHlsStream/${ this.state.playlistId }`, {
				method: 'POST',
				headers: {
					'session-id': this.props.sessionId
				}
			})
				.then(async response => {
					if (response.ok) {
						let data = await response.json();
						this.loadPlayer(data.streamUrl);
					}
				})
				.catch(error => console.log('An error occured while skipping: ' + error));
		}
	}

	loadPlayer(streamUrl) {
		if (Hls.isSupported() && streamUrl !== '') {

			if (this.state.hls) {
				this.state.hls.destroy();
			}

			this.setState({
				hls: new Hls()
			}, () => {
				this.state.hls.on(Hls.Events.FRAG_PARSING_METADATA, (event, data) => this.props.onFragParsedCallback(data));
				this.state.hls.on(Hls.Events.FRAG_CHANGED, (event, data) => this.props.onFragChangedCallback(data));
				this.state.hls.on(Hls.Events.ERROR, this.handleHlsErrors);
				this.state.hls.loadSource(streamUrl);
				this.state.hls.attachMedia(this.player);
			})

			if (!this.state.timer && this.props.streamTimeoutSec) {
				this.setState({
					timer: setTimeout(this.cutStream, this.props.streamTimeoutSec * 1000)
				})
			} else if (this.props.streamTimeoutSec) {
				clearTimeout(this.state.timer);
				this.setState({
					timer: setTimeout(this.cutStream, this.props.streamTimeoutSec * 1000)
				})
			}
		}
	}

	cutStream() {
		if (this.state.hls) {
			this.state.hls.destroy();
			this.setState({
				hls: null
			})
		}
	}

	handleLoadedData() {
		if (this.player) {
			this.player.currentTime = 0;
			this.player.play();
		}
	}

	handlePause() {
		if (this.state.hls) {
			this.state.hls.stopLoad();
		}
	}

	handlePlay() {
		if (this.state.hls) {
			this.state.hls.startLoad();
		}
	}
}

const mapStateToProps = (state) => {
	return {
		streamTimeoutSec: state.configs.streamTimeoutSec,
		sessionId: state.app.sessionId
	}
}

export default connect(mapStateToProps)(HlsPlayer)