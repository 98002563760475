import React from 'react';
import { Card } from 'react-bootstrap';

export default class About extends React.Component{

    render(){
        return <div>
            <Card>
                <Card.Header>
                    <h2 style={{ marginTop: "30px", marginBottom: "30px"}}align="center">About</h2>
                </Card.Header>
                <Card.Body style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "990px"}}>
                    <p>This app has an integrated HLS player from the library <a href="https://github.com/video-dev/hls.js/">hls.js</a>.</p>
                    <br/>
                    <h4>Stream HLS</h4>
                    <h5>Start</h5>
                    <p>To start a stream, you need to provide a valid channel id (For the sake of this demo, there's no error handling for invalid channel id). When the stream is started, here are the steps:</p>
                    <p style={{ marginLeft: "20px"}}>1. Call the HLS create endpoint in music-api with the correct headers and the channel id as a param.</p>
                    <p style={{ marginLeft: "20px"}}>2. A JSON object is returned with the playlist id and a stream URL.</p>
                    <p style={{ marginLeft: "20px"}}>3. The playlist id is stored and the stream URL is loaded in the HLS player.</p>
                    <p style={{ marginLeft: "20px"}}>4. The stream starts.</p>
                    <br/>
                    <h5>Stop</h5>
                    <p>It is possible for the user to stop the stream with the help of the controls on the video player. There will still be new segments added to the buffer until the maximum number of segments is reached.</p>
                    <br/>
                    <h5>Skip</h5>
                    <p>To skip the currently playing asset in a stream, you need to have a stream loaded in the HLS player. When the stream is skipped, here are the steps:</p>
                    <p style={{ marginLeft: "20px"}}>1. Call the HLS skip endpoint in music-api with the correct headers and the playlist id as a path variable.</p>                   
                    <p style={{ marginLeft: "20px"}}>2. A JSON object is returned with the playlist id and a new stream URL.</p>
                    <p style={{ marginLeft: "20px"}}>3. The new stream URL is loaded in the HLS player.</p>
                    <p style={{ marginLeft: "20px"}}>4. The stream resumes with the next song.</p>
                    <br/>
                    <h5>Like/Ban</h5>
                    <p>The same procedure is used to like or ban an artist or a song. First, there's needs to be an asset playing with the metadata. When there's a like or ban, here are the steps:</p>
                    <p style={{ marginLeft: "20px"}}>1. Call the Create Favorite endpoint in music-api with the correct headers, the body (state, type and asset id) and the playlist id as a path variable.</p>
                    <p style={{ marginLeft: "20px"}}>2. For demonstration purposes, the result is printed in the console.</p>
                    <br/>
                    <h4>ID3 tags</h4>
                    <h5>Parser</h5>
                    <p>To extract the tags from the encoded metadata. We implemented a parser with the help of the <a href="https://id3.org/id3v2.4.0-structure">ID3v2.4 documentation</a>.</p> 
                    <h5>Display</h5>
                    <p>To properly display the current assets metadata (ID3 tags), we need to be aware of the different events happening within the HLS player.</p>
                    <p style={{ marginLeft: "20px"}}>1. At the start of a stream, the player will load the segments from the first manifest in the buffer.</p>
                    <p style={{ marginLeft: "20px"}}>2. The player will play the first segment in the HtmlMediaElement.</p>
                    <p style={{ marginLeft: "20px"}}>3. For the remainder of the stream, the player will load the next segment from the buffer in the HtmlMediaElement and load a new segment in the buffer.</p>
                    <p>To make sure we display the metadata of the currently playing asset and not the metadata of an asset loaded in the buffer.</p>
                    <p>We verify the following:</p>
                    <p style={{ marginLeft: "20px"}}>- The currently playing segment is the first segment of an asset (segment_0.ts)</p>
                    <p style={{ marginLeft: "20px"}}>- A new stream started</p>
                    <p style={{ marginLeft: "20px"}}>- A skip action occurred</p>
                    <h4>Login</h4>
                    <p>For this demo, we are logging in using a User Device Login.  Here are the steps:</p>
                    <p style={{ marginLeft: "40px"}}>1. Login with a client id and a device id to retrieve our session id.</p>
                    <p style={{ marginLeft: "40px"}}>2. Get the issue code from Oauth with the session id.</p>
                    <p style={{ marginLeft: "40px"}}>3. Get the access token from Oauth with the issue code and the client secret.</p>
                    <p style={{ marginLeft: "20px"}}>4. The authentication token is retrieved, and we can now consume the music-api.</p>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
        </div>
    }
}