import { SET_CONFIG } from "./actionType";

const setConfig = (config) => {
  return {
    type: SET_CONFIG,
    config: config
  }
}

export const loadConfig = () => async (dispatch) => {
  fetch(`/api/configs`, {
    method: 'GET'
  })
    .then(async response => {
      if (response.ok) {
        let data = await response.json();
        dispatch(setConfig(data));
      }
    })
    .catch(error => console.log('Could not load config.' + error));
}