import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container, Navbar, Nav } from 'react-bootstrap';
import { FaQuestion } from 'react-icons/fa';
import Player from './components/demo/Demo';
import About from './components/About';

function App() {
  return (
    <React.Fragment>
      <Navbar expand="lg" bg="dark" variant="dark">
        <Container fluid={true}>
          <Navbar.Brand href="/">Kraken</Navbar.Brand>
          <Nav className="justify-content-end">
            <Nav.Link href="/About">
              <FaQuestion/>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Router>
        <Switch>
          <Route exact path="/" component={Player} />
          <Route exact path="/about" component={About} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
