import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { FaHeart, FaHeartBroken } from "react-icons/fa";

import "./MetadataVisualizer.css";

class MetadataVisualizer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentAsset: {
				title: '',
				artist: '',
				album: '',
				year: '',
				assetId: ''
			},
			assetLoaded: {
				title: '',
				artist: '',
				album: '',
				year: '',
				assetId: ''
			}
		}

		this.loadMetadataFromSegment = this.loadMetadataFromSegment.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.metadataFrag !== this.props.metadataFrag) {
			this.loadMetadataFromSegment(this.props.metadataFrag);
		}

		if (prevProps.currentFrag !== this.props.currentFrag) {
			this.showCurrentAssetMetadata(this.props.currentFrag);
		}
	}

	render() {
		return (<div id="metadata-visualizer">
				<InputGroup>
					<InputGroup.Text id="inputGroup-sizing-default">Title</InputGroup.Text>
					<FormControl
						id="title"
						value={this.state.currentAsset.title}
						readOnly
						aria-label="Default"
						aria-describedby="inputGroup-sizing-default"
					/>
					{ !this.props.isProd && 
						<Button id="metadata-visualizer-favorite-button" onClick={() => this.favorite('LIKE', 'SONG')}>
							<FaHeart />
						</Button>
					}
					{ !this.props.isProd && 
						<Button id="metadata-visualizer-favorite-button" onClick={() => this.favorite('BAN', 'SONG')}>
							<FaHeartBroken />
						</Button>
					}
				</InputGroup>
				<br />
				<InputGroup>
					<InputGroup.Text id="inputGroup-sizing-default">Artist</InputGroup.Text>
					<FormControl
						id="artist"
						value={this.state.currentAsset.artist}
						readOnly
						aria-label="Default"
						aria-describedby="inputGroup-sizing-default"
					/>
					{ !this.props.isProd && 
						<Button id="metadata-visualizer-favorite-button" onClick={() => this.favorite('LIKE', 'ARTIST')}>
							<FaHeart />
						</Button>
					}
					{ !this.props.isProd &&
						<Button id="metadata-visualizer-favorite-button" onClick={() => this.favorite('BAN', 'ARTIST')}>
							<FaHeartBroken />
						</Button>
					}
				</InputGroup>
				<br />
				<InputGroup>
					<InputGroup.Text id="inputGroup-sizing-default">Album</InputGroup.Text>
					<FormControl
						id="album"
						value={this.state.currentAsset.album}
						readOnly
						aria-label="Default"
						aria-describedby="inputGroup-sizing-default"
					/>
				</InputGroup>
				<br />
				<InputGroup>
					<InputGroup.Text id="inputGroup-sizing-default">Year</InputGroup.Text>
					<FormControl
						id="year"
						value={this.state.currentAsset.year}
						readOnly
						aria-label="Default"
						aria-describedby="inputGroup-sizing-default"
					/>
				</InputGroup>
				<InputGroup>
					<InputGroup.Text id="inputGroup-sizing-default">Asset Id</InputGroup.Text>
					<FormControl
						id="assetId"
						value={this.state.currentAsset.assetId}
						readOnly
						aria-label="Default"
						aria-describedby="inputGroup-sizing-default"
					/>
				</InputGroup>
			</div>
		);
	}

	loadMetadataFromSegment(data) {
		if (!data) {
			return;
		}

		// Loads metadata from segment into the assetLoaded variable
		const headerSize = 10;
		let metadata = data.samples[0].data;
		let header = metadata.slice(0, headerSize);

		if (String.fromCharCode(header[0], header[1], header[2]) === 'ID3' && header[3] === 4) {
			// start parsing
			let asset = {
				title: '',
				artist: '',
				album: '',
				year: '',
				assetId: ''
			};
			let position = headerSize;
			let length = metadata.length;
			const frameNameSize = 4;
			const Id3FrameSize = 10;

			while (true) {
				let firstChar = String.fromCharCode(metadata[position]);

				if (firstChar < 'A' || firstChar > 'Z') {
					break;
				}

				let frameName = new TextDecoder().decode(metadata.slice(position, position + frameNameSize));
				let frameSize = (metadata[position + 7] | metadata[position + 6] | metadata[position + 5] | metadata[position + 4]);

				if (position + frameSize > length) {
					break;
				}

				if (frameName === 'TIT2') {
					asset.title = this.getStringOfByteArray(metadata.slice(position + Id3FrameSize + 1, position + Id3FrameSize + frameSize));
				}

				if (frameName === 'TPE1') {
					asset.artist = this.getStringOfByteArray(metadata.slice(position + Id3FrameSize + 1, position + Id3FrameSize + frameSize));
				}

				if (frameName === 'TALB') {
					asset.album = this.getStringOfByteArray(metadata.slice(position + Id3FrameSize + 1, position + Id3FrameSize + frameSize));
				}

				if (frameName === 'TDRC') {
					asset.year = this.getStringOfByteArray(metadata.slice(position + Id3FrameSize + 1, position + Id3FrameSize + frameSize));
				}

				if (frameName === 'LINK') {
					let linkFrameName = this.getStringOfByteArray(metadata.slice(position + Id3FrameSize, position + Id3FrameSize + frameNameSize));

					if (linkFrameName === 'WXXX') {
						asset.assetId = this.getStringOfByteArray(metadata.slice(position + Id3FrameSize + frameNameSize + 1, position + Id3FrameSize + frameSize));
					}
				}
				position += frameSize + Id3FrameSize;
			}

			this.setState({ assetLoaded: asset });
		}
	}


	getStringOfByteArray(array) {
		var decoder = new TextDecoder();
		var decoded = decoder.decode(array);
		return decoded.replace(/\0|\uFFFD|/g, '');
	}

	showCurrentAssetMetadata(data) {
		if (!data) {
			return;
		}

		// Changes currentAsset with assetLoaded if theres no currentAsset or if a new asset is playing (semgent_0.ts)
		let myArr = (data.frag._url).match(/(\D+)(0+)(.ts$)/);
		if (this.state.currentAsset.assetId === '' || (myArr && myArr.length > 0)) {
			this.setState({ currentAsset: this.state.assetLoaded });
		}
	}

	favorite(state, type) {
		if (this.props.playlistId !== '' && this.state.currentAsset.assetId !== '') {
			var body = {
				'asset_id': 'G:' + this.state.currentAsset.assetId,
				'state': state,
				'type': type
			}
			fetch(`/api/favorite?playlistId=${this.props.playlistId}`, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					'session-id': this.props.sessionId
				},
				body: JSON.stringify(body)
			})
				.then(response => {
					if (response.status === 200) {
						console.log(state + ' ' + type + ' success')
					} else {
						console.error('Error -  ' + response.status + ' - ' + response.statusText)
					}
				});
		}
	}
}

const mapStateToProps = (state) => {
	return {
		sessionId: state.app.sessionId,
		isProd: state.configs.isProd
	}
}

export default connect(mapStateToProps)(MetadataVisualizer)