import { SET_SESSION } from "./actionType";

const setSession = (session) => {
  return {
    type: SET_SESSION,
    session: session
  }
}

export const login = (clientId) => async (dispatch) => {
    let headers = {};

    if (clientId) {
        headers['client-id'] =  clientId;
    }

    await fetch('/api/login', {
        method: 'POST',
        headers: headers
    })
        .then(async response => {
            if (response.ok) {
                let data = await response.json();
                dispatch(setSession(data));
            }
        })
        .catch(error => console.log('An error occured while logging in: ' + error));
}