import { SET_SESSION } from "../actions/actionType";

const initialState = {
  sessionId: null
}

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...state,
        sessionId: action.session.sessionId
      }

    default:
      return state
  }
}

export default AppReducer;