import React from 'react';
import { connect } from "react-redux";
import HlsPlayer from "../hlsplayer/HlsPlayer";
import MetadataVisualizer from "../metadatavisualizer/MetadataVisualizer";
import ChannelDropdown from "../channeldropdown/ChannelDropdown";
import Modal from "../modal/Modal";
import { login } from "../../actions/appActions";

import "./Demo.css";


class Demo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			channelId: null,
			playlistId: null,
			metadataFrag: null,
			currentFrag: null
		};
	}

	componentDidMount() {
		this.props.dispatch(login());
	}
	
	render() {
		return <div id="demo">
			<h2 id="demo-title">HLS player demo</h2>

			<Modal showModal={this.props.loginEnabled && !this.props.sessionId} />	

			<div id="demo-menu">
				<ChannelDropdown onSelectCallback={ (id) => this.setState({channelId: id}) }/>
				{/*empty div below to fit the dropdown with the player*/}
				<div className="empty-div"/>
			</div>

			<div id="demo-mainframe">
				<HlsPlayer onFragParsedCallback={ (frag) => this.setState({metadataFrag: frag}) }
									 onFragChangedCallback={ (frag) => this.setState({currentFrag: frag}) }
									 channelId={ this.state.channelId }
									 onStartCallback={ (id) => this.setState({playlistId: id}) }/>

				<MetadataVisualizer metadataFrag={ this.state.metadataFrag }
									currentFrag={ this.state.currentFrag }
									playlistId= { this.state.playlistId }/>
			</div>
		</div>
	}
}

const mapStateToProps = (state) => {
	return {
		loginEnabled: state.configs.loginEnabled,
		sessionId: state.app.sessionId
	}
}

export default connect(mapStateToProps)(Demo)