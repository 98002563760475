import React from "react";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";

import "./ChannelDropdown.css";

const channels = [
  {
    id: "S360-3-073",
    name: "Breezy Indie Pop Warmup"
  },
  {
    id: "S360-3-074",
    name: "Warmup"
  },
  {
    id: "S360-3-075",
    name: "R&B Warmup"
  },
  {
    id: "S360-3-076",
    name: "Calm Indie Cooldown"
  },
  {
    id: "S360-3-077",
    name: "Cooldown"
  },
  {
    id: "S360-3-079",
    name: "Rock Jams"
  },
  {
    id: "S360-3-080",
    name: "Hip-Hop"
  },
  {
    id: "S360-3-081",
    name: "Arena Anthems"
  },
  {
    id: "S360-3-082",
    name: "Current Pop"
  },
  {
    id: "S360-3-083",
    name: "Pop Classics"
  },
  {
    id: "S360-3-084",
    name: "Disco Fever"
  },
  {
    id: "S360-3-085",
    name: "EDM High-Intensity"
  },
  {
    id: "S360-3-086",
    name: "Tropical Electro"
  },
  {
    id: "S360-3-087",
    name: "Calm Indie Cooldown"
  },
  {
    id: "S360-3-088",
    name: "Ritmos Latinos"
  },
  {
    id: "S360-3-089",
    name: "EDM Power"
  },
  {
    id: "S360-3-141",
    name: "Top Rock"
  },
  {
    id: "S360-3-142",
    name: "Today's Hip-Hop"
  },
  {
    id: "S360-3-147",
    name: "Top 40 Pop"
  },
  {
    id: "S360-3-171",
    name: "Hell Week"
  }
];


class ChannelDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: undefined
    }
  }

  render() {
    return (
      <div id="channel-dropdown">
        <DropdownButton title={this.state.channel ? this.state.channel.name : "Channel"}>
          {channels.map(c => <DropdownItem
            onSelect={() => {
              this.setState({channel: c});
              this.props.onSelectCallback(c.id)}}>
            {c.name} ({c.id})
          </DropdownItem>)}
        </DropdownButton>
      </div>
    );
  }
}

export default ChannelDropdown;