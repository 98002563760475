import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { login, validateClient } from "../../actions/appActions";

import "./Modal.css";

class Modal extends Component {
	constructor(props) {
		super(props);
		this.state = {
            clientId: null,
            showErrorMessage: false
		}

        this.onClickLogin = this.onClickLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	render() {
        if (!this.props.showModal) {
            return null;
        }

		return (<div id="modal">
                    <div id="modal-container">
                        <div id="modal-content">
                            <h3 id="modal-title">Please enter your client ID</h3>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-default">Client Id</InputGroup.Text>
                                <FormControl
                                    name="clientId"
                                    value={this.state.clientId}
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    onChange={this.handleChange}
                                    onKeyDown={this.handleKeyDown}
                                />
                            </InputGroup>
                            {this.state.showErrorMessage && <p id="modal-error-message">Client ID not valid</p>}
                            <Button id="modal-login-button" onClick={() => this.onClickLogin()}>
                                Login
                            </Button>
                        </div>
                </div>
			</div>
		);
	}

    async onClickLogin() {
        await this.props.dispatch(login(this.state.clientId));
        if (!this.props.sessionId) {
            this.setState({showErrorMessage: true});
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleKeyDown(event) {
        if (event.key === 'Enter') {
            this.onClickLogin();
        }
    }
}

const mapStateToProps = (state) => {
	return {
		sessionId: state.app.sessionId
	}
}


export default connect(mapStateToProps)(Modal)